import { memo } from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Layout } from '../components'
import { HeadTag } from '@dy/commons/components'
import { useI18n, callLocales } from '@/i18n'
import { callAPI } from '@/api'
import { getRelative, mediaQuery, createGrid, getRegularButton, getP50, getP24 } from '@/styles'
import { useScrollTop } from '@dy/commons/hooks'

export interface ErrorProps {
  error?: any,
  initLocales?: any,
}

const Main = styled.main`
  ${createGrid()}
  overflow-x: hidden;
  padding: 0;
  position: relative;
  height: 100vh;
  align-items: center;

  ${mediaQuery.greaterThan('tablet')`
    overflow-x: inherit;
  `}

  section {
    grid-column: 1 / span 6;

    ${mediaQuery.greaterThan('tablet')`
      grid-column: 4 / span 6;
    `}

    h2, p { text-align: center; }

    h2 {
      ${getP50()}
      margin-bottom: ${getRelative(20, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: ${getRelative(40, 'desktop')};
      `}
    }

    p {
      ${getP24()}
      margin-bottom: ${getRelative(50, 'mobile')};
      color: ${({ theme }) => theme.colors.greendark};

      ${mediaQuery.greaterThan('tablet')`
        margin-bottom: ${getRelative(40, 'desktop')};
      `}
    }

    button {
      ${getRegularButton()}
      transition: background-color 300ms ${({ theme }) => theme.ease};
      display: block;
      margin: 0 auto;
    }
  }
`

export async function getStaticProps({ locale }) {
  const [locales] = await callLocales({ locale })
  const [layout_data] = await callAPI({ type: 'layout', params: { locale } })

  return {
    props: {
      locales,
      layout_data
    },
  }
}

const ErrorPage: NextPage<ErrorProps> = memo((props) => {
  const { push } = useRouter()
  const { locales: { error_page } } = useI18n()

  const onClick = () => {
    push('/')
  }

  useScrollTop()

  return (
    <>
      <HeadTag data={error_page.seo} slug='404'/>
      <Main>
        <section>
          <h2>{error_page.title}</h2>
          <p>{error_page.description}</p>
          <button onClick={() => onClick()}>{error_page.goback_homepage}</button>
        </section>
      </Main>
    </>
  )
})

//@ts-ignore
ErrorPage.Layout = Layout

export default ErrorPage
